import { Divider, Table, DatePicker, TimePicker, ConfigProvider } from "antd";
import { Modal, Icon, Button } from "@alpaca/framework";
import React, { useState } from "react";
import MachineChart from "../Chart/Chart";
import TempChart from "../Chart/TempChart";
import { slider } from "../slider/slider";
import "./machine.styles.css";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";
import WinderTempChart from "../Chart/winderTemperatureChart";
import HumidityChart from "../Chart/winderHumidityChart";
import { datetrans } from "../Date/datetrans";
import dayjs from "dayjs";
import IRTemperatureChart from "../Chart/IRTemperature";
import SawCountChart from "../Chart/sawCount";

let now = new Date();
now.setTime(now.getTime() - 24 * 60 * 60 * 1000);

export default function MachineStatus(props) {
  // const [isLoading, setIsLoading] = useState(false);

  function generateTable() {
    let data = [];
    for (let i = 0; i < props.dataSource.length; ++i) {
      data.push(
        <Table
          dataSource={props.dataSource[i]}
          columns={props.columns[i]}
          pagination={false}
        />
      );
      data.push(<Divider />);
    }
    return data;
  }


  const Schedule = {
    temp1: {
      min: 0,
      max: 200,
    },
    temp2: {
      min: 0,
      max: 200,
    },
    temp3: {
      min: 0,
      max: 200,
    },
    temp4: {
      min: 0,
      max: 200,
    },
    humidtemp: {
      min: 0, 
      max: 200
    }, 
    humid: {
      min: 30, 
      max: 40
    }
  };

  return (
    <div>
      <Modal
        title={props.name.display_name}
        width={1000}
        height={650}
        open={props.open}
        onCancel={() => {
          console.log("Here", props.data);
          props.close();
        }}
        footer={
          <div>
            {/* <div>{sliderString}</div> */}
            <div className="controls-container">
              <div className="controls-time">
                {/* <span>{sliderInst.displayStartDate()}</span>
                                <span>{sliderInst.displayStartTime()}</span> */}
                <p className="time-text">Start Date</p>
                <DatePicker
                  value={dayjs(new Date(props.start))}
                  onChange={(e) => {
                    if (e) {
                      let dateObj = new Date(e.$d);
                      let startDateObj = new Date(props.start);
                      let datestring = `${dateObj.getFullYear()}-${
                        dateObj.getMonth() + 1
                      }-${dateObj.getDate()}`;
                      let timestring = `${startDateObj.getHours()}:${startDateObj.getMinutes()}:${startDateObj.getSeconds()}`;
                      let wholeString = datestring + " " + timestring;
                      let wholeDateObj = new Date(wholeString);
                      props.changeStart(
                        datetrans(wholeDateObj)
                          .findPreviousIntegerHours()
                          .toISOString()
                      );
                    }
                  }}
                />
                <TimePicker
                  value={dayjs(new Date(props.start))}
                  onChange={(e) => {
                    if (e) {
                      let dateObj = new Date(e.$d);
                      let startDateObj = new Date(props.start);
                      let datestring = `${startDateObj.getFullYear()}-${
                        startDateObj.getMonth() + 1
                      }-${startDateObj.getDate()} `;
                      let timestring = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
                      let wholeString = datestring + " " + timestring;
                      let wholeDateObj = new Date(wholeString);
                      props.changeStart(
                        datetrans(wholeDateObj)
                          .findPreviousIntegerHours()
                          .toISOString()
                      );
                    }
                  }}
                />
              </div>
              <div className="controls-center">
                <Button
                  className="control-btn"
                  onClick={() => {
                    props.reloadData();
                  }}
                  loading={props.loading}
                >
                  Redraw
                </Button>
                <Button
                  className="control-today-btn"
                  onClick={() => {
                    let nowOut = new Date();
                    nowOut.setTime(nowOut.getTime() - 24 * 60 * 60 * 1000);
                    props.changeStart(
                      datetrans(nowOut).findPreviousIntegerHours().toISOString()
                    );
                    props.changeEnd(
                      datetrans(new Date())
                        .findPreviousIntegerHours()
                        .toISOString()
                    );
                    // props.reloadData();
                  }}
                  // loading={isLoading}
                >
                  Today
                </Button>
                {/* <Button
                                    onClick={() => {
                                        sliderInst.shiftLeft();
                                        setSliderString(
                                            sliderInst.statusString
                                        );
                                        props.reloadData(
                                            sliderInst.returnValue().start,
                                            sliderInst.returnValue().end
                                        );
                                    }}
                                    className="controls-move"
                                >
                                    <Icon icon={"caret_left"} />
                                </Button>
                                <Button
                                    onClick={() => {
                                        sliderInst.shrinkWindow();
                                        setSliderString(
                                            sliderInst.statusString
                                        );
                                        props.reloadData(
                                            sliderInst.returnValue().start,
                                            sliderInst.returnValue().end
                                        );
                                    }}
                                    className="controls-zoom"
                                >
                                    <BiZoomIn />
                                </Button>
                                <div className="controls-interval-main">
                                    <Button
                                        onClick={() => {
                                            sliderInst.expand();
                                            setSliderString(
                                                sliderInst.statusString
                                            );
                                            props.reloadData(
                                                sliderInst.returnValue().start,
                                                sliderInst.returnValue().end
                                            );
                                        }}
                                        className="controls-interval"
                                    >
                                        <Icon icon={"caret_up"} />
                                    </Button>
                                    <p> {sliderInst.sliderIntervalString()}</p>
                                    <Button
                                        onClick={() => {
                                            sliderInst.shrink();
                                            setSliderString(
                                                sliderInst.statusString
                                            );
                                            props.reloadData(
                                                sliderInst.returnValue().start,
                                                sliderInst.returnValue().end
                                            );
                                        }}
                                        className="controls-interval"
                                    >
                                        <Icon icon={"caret_down"} />
                                    </Button>
                                </div>
                                <Button
                                    onClick={() => {
                                        sliderInst.doubLingWindow();
                                        setSliderString(
                                            sliderInst.statusString
                                        );
                                        props.reloadData(
                                            sliderInst.returnValue().start,
                                            sliderInst.returnValue().end
                                        );
                                    }}
                                    className="controls-zoom"
                                >
                                    <BiZoomOut />
                                </Button>
                                <Button
                                    onClick={() => {
                                        sliderInst.shiftRight();
                                        setSliderString(
                                            sliderInst.statusString
                                        );
                                        props.reloadData(
                                            sliderInst.returnValue().start,
                                            sliderInst.returnValue().end
                                        );
                                    }}
                                    className="controls-move"
                                >
                                    <Icon icon={"caret_right"} />
                                </Button> */}
              </div>
              <div className="controls-time">
                {/* <span>{sliderInst.displayEndDate()}</span>
                                <span>{sliderInst.displayEndTime()}</span> */}
                <p className="time-text">End Date</p>
                <DatePicker
                  value={dayjs(new Date(props.end))}
                  onChange={(e) => {
                    if (e) {
                      let dateObj = new Date(e.$d);
                      let startDateObj = new Date(props.end);
                      let datestring = `${dateObj.getFullYear()}-${
                        dateObj.getMonth() + 1
                      }-${dateObj.getDate()}`;
                      let timestring = `${startDateObj.getHours()}:${startDateObj.getMinutes()}:${startDateObj.getSeconds()}`;
                      let wholeString = datestring + " " + timestring;
                      let wholeDateObj = new Date(wholeString);
                      props.changeEnd(
                        datetrans(wholeDateObj)
                          .findPreviousIntegerHours()
                          .toISOString()
                      );
                    }
                  }}
                />
                <TimePicker
                  value={dayjs(new Date(props.end))}
                  onChange={(e) => {
                    if (e) {
                      let dateObj = new Date(e.$d);
                      let startDateObj = new Date(props.end);
                      let datestring = `${startDateObj.getFullYear()}-${
                        startDateObj.getMonth() + 1
                      }-${startDateObj.getDate()} `;
                      let timestring = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
                      let wholeString = datestring + " " + timestring;
                      let wholeDateObj = new Date(wholeString);
                      props.changeEnd(
                        datetrans(wholeDateObj)
                          .findPreviousIntegerHours()
                          .toISOString()
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        }
        onOk={() => {
          props.close();
        }}
        closable={false}
      >
        <div>
          {(props.name === "temp1" ||
            props.name === "temp2" ||
            props.name === "temp3" ||
            props.name === "temp4") && (
            <div className="modal-scroll">
              <WinderTempChart
                data={props.data}
                start={props.displaystart}
                end={props.displayEnd}
                min={Schedule[props.name].min}
                max={Schedule[props.name].max}
              />
            </div>
          )}
          {props.name === "humidtemp" && (
            <div className="modal-scroll">
            <IRTemperatureChart                 data={props.data}
                start={props.displaystart}
                end={props.displayEnd}
                min={Schedule[props.name].min}
                max={Schedule[props.name].max} />
              {/* {JSON.stringify(props.data)} */}
            </div>
          )}
          {props.name === "humid" && (
            <div className="modal-scroll">
              <HumidityChart
                data={props.data}
                start={props.displaystart}
                end={props.displayEnd}
                min={Schedule[props.name].min}
                max={Schedule[props.name].max}
              />
              {/* {props.data} */}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

MachineStatus.defaultProps = {
  open: false,
  dataSource: [],
  columns: [],
  name: "",
};
