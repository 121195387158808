import { Line } from "@ant-design/plots";
import axios from "axios";
import React from "react";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { datetrans } from "../Date/datetrans";
// import { config } from "dotenv";

<script
  type="text/javascript"
  src="https://unpkg.com/@ant-design/plots@latest/dist/plots.min.js"
></script>;

// const data = {
//     "0": [
//         {
//             "status": "N/A",
//             "time": "24.00",
//             "milliseconds": 86400000
//         }
//     ],
//     "1": [
//         {
//             "status": "N/A",
//             "time": "10.43",
//             "milliseconds": 37560000
//         },
//         {
//             "status": "on",
//             "time": "0.44",
//             "milliseconds": 1590000
//         },
//         {
//             "status": "off",
//             "time": "13.13",
//             "milliseconds": 47250000
//         }
//     ],
//     "2": [
//         {
//             "status": "off",
//             "time": "3.38",
//             "milliseconds": 12180000
//         },
//         {
//             "status": "on",
//             "time": "7.71",
//             "milliseconds": 27750000
//         },
//         {
//             "status": "off",
//             "time": "12.91",
//             "milliseconds": 46470000
//         }
//     ],
//     "3": [
//         {
//             "status": "off",
//             "time": "2.33",
//             "milliseconds": 8400000
//         },
//         {
//             "status": "on",
//             "time": "11.54",
//             "milliseconds": 41548785
//         }
//     ],
//     "4": [],
//     "5": [],
//     "6": []
// }
// },
// ],
// height: 350,
// };

export default function IRTemperatureChart(props) {
  function computeMinMax(array) {
    let newArray = [];
    console.log("117", array);
    if (array.length === 0) {
      return {
        min: 0,
        max: 0,
        avgArr: [],
        validArr: [],
      };
    }
    let elem = array[0];

    for (let i = 0; i < array.length; ++i) {
      elem = array[i];
      if (
        elem.regression_temperature_ai1 &&
        elem.regression_temperature_ai2 &&
        elem.regression_temperature_ai3 &&
        elem.regression_temperature_ai4 &&
        elem.regression_temperature_ai5 &&
        elem.regression_temperature_ai6
      ) {
        let sum =
          Number(elem.regression_temperature_ai1) +
          Number(elem.regression_temperature_ai2) +
          Number(elem.regression_temperature_ai3) +
          Number(elem.regression_temperature_ai4) +
          Number(elem.regression_temperature_ai5) +
          Number(elem.regression_temperature_ai6);
        // console.log("Sum", sum, elem)
        newArray.push(Math.floor(sum / 6 + 0.5));
      } else {
        newArray.push(null);
      }
    }

    return {
      avgArr: newArray,
    };
  }

  const [config, setConfig] = useState({});
  const [data, setData] = useState([
    {
      datetime: new Date(),
      temperature: 15,
    },
  ]);

  // function flatData(inputData) {
  //   let result = [];
  //   for (let i = 0; i < 7; ++i) {
  //     result = [
  //       ...result,
  //       ...inputData[i].map((elem) => {
  //         return {
  //           x: elem["status"],
  //           y: Number(elem["time"]),
  //           fillColor:
  //             elem["status"] === "idle"
  //               ? "#FFFF00"
  //               : elem["status"] === "on"
  //               ? "#008000"
  //               : "#ff0000",
  //         };
  //       }),
  //     ];
  //   }

  //   //console.log("Flat Out", result);
  //   return result;
  // }

  // function generateConfig(data) {
  //   return {
  //     options: {
  //       series: [
  //         {
  //           name: "AI1 Temperature",
  //           type: "line",
  //           data: data.map((elem) => {
  //             return elem.regression_temperature_ai1 ? elem.regression_temperature_ai1 : 15;
  //           }),
  //         },
  //         {
  //           name: "AI2 Temperature",
  //           type: "line",
  //           data: data.map((elem) => {
  //             return elem.regression_temperature_ai2 ? elem.regression_temperature_ai2 : 15;
  //           }),
  //         },
  //         {
  //           name: "AI3 Temperature",
  //           type: "line",
  //           data: data.map((elem) => {
  //             return elem.regression_temperature_ai3 ? elem.regression_temperature_ai3 : 15;
  //           }),
  //         },
  //         {
  //           name: "AI4 Temperature",
  //           type: "line",
  //           data: data.map((elem) => {
  //             return elem.regression_temperature_ai4 ? elem.regression_temperature_ai4 : 15;
  //           }),
  //         },
  //         {
  //           name: "AI5 Temperature",
  //           type: "line",
  //           data: data.map((elem) => {
  //             return elem.regression_temperature_ai5 ? elem.regression_temperature_ai5 : 15;
  //           }),
  //         },
  //         {
  //           name: "AI6 Temperature",
  //           type: "line",
  //           data: data.map((elem) => {
  //             return elem.regression_temperature_ai6 ? elem.regression_temperature_ai6 : 15;
  //           }),
  //         },
  //       ],
  //       chart: {
  //         height: 350,
  //         // type: "line",
  //         zoom: {
  //           enabled: true,
  //           type: "x",
  //           autoScaleYaxis: false,
  //           zoomedArea: {
  //             fill: {
  //               color: "#90CAF9",
  //               opacity: 0.4,
  //             },
  //             stroke: {
  //               color: "#0D47A1",
  //               opacity: 0.4,
  //               width: 1,
  //             },
  //           },
  //         },
  //         toolbar: {
  //           export: {
  //             csv: {
  //               headerCategory: "DateTime",
  //               columnDelimiter: ",",
  //               filename: "tempReport",
  //               dateFormatter: (elem) => {
  //                 return `${new Date(elem).getUTCDate()} ${new Date(
  //                   elem
  //                 ).getUTCHours()}:${new Date(elem).getUTCMinutes()}:${new Date(
  //                   elem
  //                 ).getUTCSeconds()}`;
  //               },
  //             },
  //           },
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         curve: "straight",
  //       },
  //       title: {
  //         // text: "Product Trends by Month",
  //         align: "left",
  //       },
  //       tooltip: {
  //         enabled: true,
  //         x: {
  //           show: true,
  //           formatter: (elem) => {
  //             return `${new Date(elem).getUTCDate()} ${new Date(
  //               elem
  //             ).getUT  CHours()}:${new Date(elem).getUTCMinutes()}:${new Date(
  //               elem
  //             ).getUTCSeconds()}`;
  //           },
  //         },
  //       },
  //       grid: {
  //         row: {
  //           colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
  //           opacity: 0.5,
  //         },
  //       },
  //       xaxis: {
  //         type: "datetime",
  //         categories: data.map((elem) => {
  //           return new Date(elem.date_and_time).getTime() - 6 * 1000 * 60 * 60;
  //         }),
  //         // labels: {
  //         //             formatter: function (value, timestamp) {
  //         // //              return `${new Date(timestamp).getHours()}:${new Date(timestamp).getMinutes()}`; // The formatter function overrides format property
  //         //             },
  //         // },
  //         tickAmount: 10,
  //       },
  //     },
  //     type: "line",
  //     height: 350,
  //   };
  // }

  function generateConfig() {
    let tempData = data.filter((elem, index) => {
      return index % 10 === 0;
    });
    // let minMax = computeMinMax(tempData);
    // console.log("MinMax", minMax);
    return {
      series: [
        // {
        //   name: "AI1 Temperature",
        //   type: "line",
        //   data: tempData.map((elem) => {
        //     return elem.regression_temperature_ai1;
        //   }),
        // },
        // {
        //   name: "AI2 Temperature",
        //   type: "line",
        //   data: tempData.map((elem) => {
        //     return elem.regression_temperature_ai2;
        //   }),
        // },
        // {
        //   name: "AI3 Temperature",
        //   type: "line",
        //   data: tempData.map((elem) => {
        //     return elem.regression_temperature_ai3;
        //   }),
        // },
        // {
        //   name: "AI4 Temperature",
        //   type: "line",
        //   data: tempData.map((elem) => {
        //     return elem.regression_temperature_ai4;
        //   }),
        // },
        // {
        //   name: "AI5 Temperature",
        //   type: "line",
        //   data: tempData.map((elem) => {
        //     return elem.regression_temperature_ai5;
        //   }),
        // },
        // {
        //   name: "AI6 Temperature",
        //   type: "line",
        //   data: tempData.map((elem) => {
        //     return elem.regression_temperature_ai6;
        //   }),
        // },
        {
          name: "Temperature",
          type: "line",
          data: [
            null,
            ...data.map((elem) => {
              return elem.room_temperature;
            }),
            null,
          ],
        },
      ],
      options: {
        chart: {
          height: "100px",
          width: "250px",
          // type: "line",
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: "#90CAF9",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
                opacity: 0.4,
                width: 0.5,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },

          toolbar: {
            export: {
              csv: {
                headerCategory: "Date,Time",
                columnDelimiter: ",",
                filename: "tempReport",
                dateFormatter: (elem) => {
                  let dateTransInst = datetrans(new Date(elem));
                  let shiftedDate = dateTransInst.shiftForward();
                  let newDateTransInst = datetrans(shiftedDate);
                  return newDateTransInst.formatCsv();
                },
              },
            },
          },

          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        fill: {
          type: "solid",
          opacity: [0.35, 1],
        },
        markers: {
          size: 0,
        },

        //   xAxes: [{
        //               ticks: {
        //                   // Show all labels
        //                   autoSkip: false,
        //                   callback: function(tick) {
        //                       // var characterLimit = 20;
        //                       // if (tick.length >= characterLimit) {
        //                       //     return tick.slice(0, tick.length).substring(0, characterLimit - 1).trim() + 'ttt';;
        //                       // }
        //                       return tick;
        //                   }
        //               }
        // }],

        tooltip: {
          x: {
            show: true,
            formatter: (elem) => {
              let dateTransInst = datetrans(new Date(elem));
              let shiftedDate = dateTransInst.shiftForward();
              let newDateTransInst = datetrans(shiftedDate);
              return newDateTransInst.formatX();
            },
          },
        },

        xaxis: {
          type: "datetime",
          categories: [
            new Date(props.start).getTime() - 6 * 1000 * 60 * 60,
            ...data.map((elem) => {
              return new Date(elem.datetime).getTime() - 6 * 1000 * 60 * 60;
            }),
            new Date(props.end).getTime() - 6 * 1000 * 60 * 60,
          ],
          labels: {
            formatter: function (value, timestamp) {
              let dateTransInst = datetrans(new Date(timestamp));
              let shiftedDate = dateTransInst.shiftForward();
              let newDateTransInst = datetrans(shiftedDate);
              return newDateTransInst.formatHours();
            }, // The formatter function overrides format property
          },

          tickAmount:
            (new Date(props.end) - new Date(props.start)) / (60 * 60 * 1000),
        },
        yaxis: [
          {
            title: {
              text: "temperature",
            },
            minWidth: 12,
            maxWidth: 12,
            min: props.min,
            max: props.max,
            tickAmount: 8,
          },
        ],
      },
    };
  }

  useEffect(() => {
    if (props.data !== "N/A") {
      // //console.log("254", props.data);
      //console.log("241", props.data);
      //console.log("242", generateConfig(props.data));
      setData(props.data);
      // setConfig(generateConfig(props.data))
      window.dispatchEvent(new Event("resize"));
    }
  }, [props.data]);

  return (
    <div>
      {/* {JSON.stringify(generateConfig(data))} */}
      <Chart {...generateConfig(data)} height={"415px"} />
      {/* {Object.keys(config) !== 0 ? (
        <div>
      {/* <Chart {...configEx} />{" "} */}
      {/* </div> */}
      {/* ) : ( */}
      {/* <h1> Not Networked Yet, no History Data</h1> */}
      {/* )}  */}
    </div>
  );
}
