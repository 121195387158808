import logo from "./assets/logos/Persona-Logo-grey-50.png";
import "./App.css";
// import Transfer from "./transfer.commponent";
import Status from "./status.component";
import Map from "./MachineStatus/map.component";
import Controller from "./Controller.component";
import MachineChart from "./Chart/Chart";
import { Button } from "antd";

// import sound from "./assets/dockpack-complete.wav";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="title">Facility Status</h1>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div>
        <Controller />
        {/* <Button
          onClick={() => {
            var audio = new Audio(sound);
            audio.play();
          }}
        >
          {" "}
          TEST SOUND{" "}
        </Button> */}
        {/* <MachineChart /> */}
        {/* <button onClick={() => {
          printLabel([
    `^XA
    ^MMT
    ^PW812
    ^LL1218
    ^LS0
    ^FT143,855^A0B,121,127^FB705,1,0,C^FH\^FDTESTPART^FS
    ^FO179,25^GB0,1171,4^FS
    ^BY3,3,353^FT568,1124^BCB,,Y,N
    ^FD>:TESTLABEL^FS
    ^FT658,1198^ADB,36,20^FH\^FDDate:^FS
    ^FT658,1055^ADB,36,20^FH\^FDTESTDATE^FS
    ^FT719,1198^ADB,36,20^FH\^FDUser:^FS
    ^FT719,1055^ADB,36,20^FH\^FDTEST^FS
    ^FT772,1198^ACB,36,20^FB185,1,0,C^FH\^FDSpool#:^FS
    ^FT772,1022^ACB,36,20^FB965,1,0,C^FH\^FDTESTITEM^FS
    ^FO618,25^GB0,1171,4^FS
    ^FO622,607^GB72,0,4^FS
    ^FT658,607^ADB,36,20^FH\^FDTime:^FS
    ^FT658,464^ADB,36,20^FH\^FD${new Date().toLocaleTimeString()}^FS
    ^PQ1,0,1,Y^XZ`
        .replace(/\s+/g, "")
        .replace(/_/g, " "),
])
        }}> CLICK </button> */}

        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </div>
    </div>
  );
}

export default App;
