const { datetrans } = require("../Date/datetrans");

function dateDiff(milliseconds) {
    let seconds = milliseconds / 1000;
    function computeHour() {
        let hours = Math.floor(seconds / 3600);
        return hours;
    }

    function computeMinutes() {
        let hours = computeHour();
        let remainingSeconds = seconds - hours * 3600;
        let remainingMinutes = Math.floor(remainingSeconds / 60);
        return remainingMinutes;
    }

    function computeSeconds() {
        return seconds - computeHour() * 3600 - computeMinutes() * 60;
    }

    function generateCommentTime() {
        return `${computeHour()} hr ${computeMinutes()} m`;
    }

    return {
        computeHour: computeHour,
        computeMinutes: computeMinutes,
        computeSeconds: computeSeconds,
        generateCommentTime: generateCommentTime,
    };
}


function slider(defaultStart, defaultEnd, defaultShiftSize) {
    let start = defaultStart
    let end = defaultEnd
    let shiftSize = defaultShiftSize



    const addOperation = (oldValue, size) => {
        let tempDateObj = new Date(oldValue.toISOString())
        tempDateObj.setTime(tempDateObj.getTime() + size)
        return tempDateObj
    }

    const shiftLeft = () => {
        start = addOperation(start, (-1) * shiftSize)
        end = addOperation(end, (-1) * shiftSize)
    }

    const shiftRight = () => {
        start = addOperation(start, shiftSize)
        end = addOperation(end, shiftSize)
    }

    const doubLingWindow = () => {
        start = addOperation(start, (-1) * shiftSize)
        end = addOperation(end, shiftSize)
    }

    const shrinkWindow = () => {
        start = addOperation(start, shiftSize)
        end = addOperation(end, (-1) * shiftSize)
    }

    const expand = () => {
        shiftSize = shiftSize * 2
    }

    const shrink = () => {
        shiftSize = shiftSize * 0.5
    }

    const print = () => {
        console.log("Starting" + start.toISOString() + "Ending" + end.toISOString() + "Shift Size" + shiftSize)
    }

    const statusString = () => {
        return `Current Interval ${start} - ${end}, moving interval ${dateDiff(shiftSize).generateCommentTime()}`
    }

    const sliderIntervalString = () => {
        return dateDiff(shiftSize).generateCommentTime()
    }

    const displayDate = (startDate) => {
        return `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`
    }

    const displayTime = (startDate) => {
        return `${startDate.getHours()}:${startDate.getMinutes()}:${startDate.getSeconds()}`
    }

    const displayStartDate = () =>
      displayDate(datetrans(start).findPreviousIntegerHours());
    const displayStartTime = () =>
      displayTime(datetrans(start).findPreviousIntegerHours());

    const displayEndDate = () =>
      displayDate(datetrans(end).findNextIntegerHours());
    const displayEndTime = () =>
      displayTime(datetrans(end).findNextIntegerHours());

    const returnValue = () => {
        return {
          start: datetrans(start).findPreviousIntegerHours().toISOString(),
          end: datetrans(end).findNextIntegerHours().toISOString(),
        };
    }

    return {
        shiftLeft: shiftLeft,
        shiftRight: shiftRight,
        expand: expand,
        shrink: shrink,
        print: print,
        doubLingWindow: doubLingWindow,
        shrinkWindow: shrinkWindow,
        statusString: statusString,
        returnValue: returnValue,
        displayTime: displayTime,
        displayDate: displayDate,
        sliderIntervalString: sliderIntervalString,
        displayStartDate,
        displayStartTime,
        displayEndDate,
        displayEndTime
    }

}

module.exports = {
    slider: slider

}