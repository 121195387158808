import React, { useEffect, useState } from "react";
import Map from "./MachineStatus/map.component";
import Status from "./status.component";
import axios from "axios";
import MachineStatus from "./MachineStatus/Machine.component";
import ChartLegend from "./Chart/ChartLegend.component";
import { datetrans } from "./Date/datetrans";

export default function Controller() {
  const [status, setStatus] = useState({
    temp1: {
      temperature: 0,
      open: false,
    },
    temp2: {
      temperature: 0,
      open: false,
    },
    temp3: {
      temperature: 0,
      open: false,
    },
    temp4: {
      temperature: 0,
      open: false,
    },
    humid: {
      temperature: 0,
      humidity: 0,
      open: false,
    },
  });

  const [machines, setMachines] = useState([]);
  const [monut, setMount] = useState(false);
  const [refreshTime, setlastRefreshTime] = useState(new Date());

  const [refreshIntervalID, setRefreshIntervalID] = useState(null);

  const [data, setData] = useState({});

  let nowOut = new Date();
  nowOut.setTime(nowOut.getTime() - 24 * 60 * 60 * 1000);

  const [startInterval, setStart] = useState(
    datetrans(nowOut).findPreviousIntegerHours().toISOString()
  );
  const [endInterval, setEnd] = useState(
    datetrans(new Date()).findPreviousIntegerHours().toISOString()
  );
  const [axisStartInterval, setaxisStart] = useState(
    datetrans(nowOut).findPreviousIntegerHours().toISOString()
  );
  const [axisendInterval, setaxisEnd] = useState(
    datetrans(new Date()).findPreviousIntegerHours().toISOString()
  );

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState("col");

  const [indicator, SetIndicator] = useState(true);
  useEffect(() => {
    if (indicator === true) {
      //console.log("Back to be able to refresh");
      clearInterval(refreshIntervalID);
      if (refreshIntervalID) {
        reloadData();
      }
    } else if (indicator === false) {
      //console.log("Not able to refresh");
      clearInterval(refreshIntervalID);
    }
  }, [indicator]);

  const [buttonloading, setButtonLoading] = useState(false);

  const programColumns = [
    {
      title: "Program Name",
      dataIndex: "program_name",
    },
    {
      title: "Program Line",
      dataIndex: "program_line",
    },
    {
      title: "Machine",
      dataIndex: "machine",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: function (text, record, index) {
        if (record.program_status === "running") {
          return <div style={{ backgroundColor: "green" }}> &nbsp; </div>;
        } else {
          return <div style={{ backgroundColor: "red" }}> &nbsp; </div>;
        }
      },
    },
    {
      title: "Time",
      dataIndex: "program_time",
    },
  ];

  // async function getRobotStatus(oldStatus) {
  //   let flag = 1;
  //   let newStatus;
  //   let result = await axios
  //     .get(`${process.env.REACT_APP_URL}/getProgram`)
  //     .catch((error) => {
  //       newStatus = {
  //         ...status,
  //         sawRobot: {
  //           ...oldStatus["sawRobot"],
  //           status: false,
  //         },
  //       };
  //       //console.log("New Status", newStatus);
  //       flag = 0;
  //     });
  async function getData(oldStatus) {
    console.log("Called", process.env);
    //console.log("Status");
    await axios.get(`${process.env.REACT_APP_URL}/getTables`).then((res) => {
      setStatus(res.data.table);
      setlastRefreshTime(new Date());
      setMachines(res.data.machines);
    });
  }
  // async function getRobotStatus(oldStatus) {
  //   let flag = 1;
  //   let newStatus;
  //   let result = await axios
  //     .get(`${process.env.REACT_APP_URL}/getProgram`)
  //     .catch((error) => {
  //       newStatus = {
  //         ...status,
  //         sawRobot: {
  //           ...oldStatus["sawRobot"],
  //           status: false,
  //         },
  //       };
  //       //console.log("New Status", newStatus);
  //       flag = 0;
  //     });

  //   // //console.log("Result", result.status);

  //   if (flag === 1) {
  //     let newStatus = {
  //       ...oldStatus,
  //       sawRobot: {
  //         ...oldStatus["sawRobot"],
  //         status: true,
  //       },
  //     };
  //     let taskStateIndex = result.data.search("TASK STATES");
  //     let programStateIndex = result.data.search("PROGRAM STATES");
  //     let segment = result.data.substr(
  //       taskStateIndex,
  //       programStateIndex - taskStateIndex
  //     );
  //     //console.log("Segment", segment.split("\n"));
  //     // Split array of array
  //     let arrayOfArray = [];
  //     let initialArray = [];
  //     let originalSegment = segment.split("\n");
  //     for (let i = 0; i < segment.split("\n").length; ++i) {
  //       let current = originalSegment[i];
  //       if (current === "") {
  //         arrayOfArray.push(initialArray);
  //         initialArray = [];
  //       } else {
  //         initialArray.push(current);
  //       }
  //     }
  //     //console.log("Array of Array", arrayOfArray);
  //     let obj = {};
  //     let arrayOfObj = [];
  //     for (let eachArray of arrayOfArray.filter((elem) => {
  //       return elem.length !== 0;
  //     })) {
  //       if (eachArray.length !== 1) {
  //         obj.detail = eachArray;
  //         arrayOfObj.push(obj);
  //         obj = {};
  //       } else {
  //         obj.header = eachArray;
  //       }
  //     }
  //     //console.log("Array of Objects", arrayOfObj);
  //     let insert = [];
  //     for (let eachObj of arrayOfObj) {
  //       let header = eachObj.header[0];
  //       if (header.search("@") !== -1) {
  //         let headerSplit = header.split(" ");
  //         let status;
  //         if (headerSplit.includes("RUNNING")) {
  //           status = "running";
  //         } else if (headerSplit.includes("PAUSED")) {
  //           status = "paused";
  //         }
  //         let filterSplit = headerSplit.filter((elem) => {
  //           return elem !== "" && elem !== "RUNNING" && elem !== "PAUSED";
  //         });
  //         //console.log("Split", filterSplit);
  //         let index = filterSplit.findIndex((elem) => {
  //           return elem === "@";
  //         });
  //         //console.log("SearchIndex", index);

  //         if (index >= 1 && index < filterSplit.length - 1) {
  //           let name = filterSplit[index - 1];
  //           let line = filterSplit[index + 1];
  //           insert.push({
  //             measured_time: new Date().toISOString(),
  //             program_name: name,
  //             program_line: line,
  //             program_status: status,
  //           });
  //         }
  //       }
  //     }

  //     let time = await getProgramTime();
  //     insert = updateProgramTime(
  //       time,
  //       insert.map((elem) => {
  //         return {
  //           ...elem,
  //           machine: "Bar Robot",
  //         };
  //       })
  //     );

  //     newStatus["sawRobot"].dataSource.push(
  //       insert.map((elem) => {
  //         return {
  //           ...elem,
  //           machine: "Bar Robot",
  //           program_time:
  //             elem.program_time || elem.program_time === "0"
  //               ? elem.program_time
  //               : "N/A",
  //         };
  //       })
  //     );

  //     newStatus["sawRobot"].columns.push(programColumns);
  //     return newStatus;
  //   }

  //   return newStatus;
  // }

  // function updateProgramTime(arrayOfProgram, origProgram) {
  //   let newProgram = [];
  //   for (let program of arrayOfProgram) {
  //     newProgram = origProgram.map((elem) => {
  //       if (
  //         elem.program_name === program.program_name &&
  //         elem.machine === program.machine
  //       ) {
  //         return {
  //           ...elem,
  //           program_time: program.program_time,
  //         };
  //       } else {
  //         return elem;
  //       }
  //     });
  //   }
  //   // //console.log("307", newProgram)
  //   // setPrograms(newProgram)
  //   return newProgram;
  // }

  // async function getProgramTime() {
  //   function removeFrontSpace(str) {
  //     let flag = 0;
  //     let resultStr = "";
  //     for (let char of str) {
  //       if (flag === 1) {
  //         resultStr = resultStr + char;
  //       } else if (char !== " ") {
  //         flag = 1;
  //         resultStr = resultStr + char;
  //       }
  //     }
  //     return resultStr;
  //   }

  //   let result = await axios.get(
  //     `${process.env.REACT_APP_URL}/getTimer`
  //     //   headers: {
  //     //     "Access-Control-Allow-Private-Network": true,
  //     //     "Access-Control-Allow-Origin": "*",
  //     //     Accept: "application/json, text/plain, */*",
  //     //   },
  //   );
  //   let headerSearch = result.data.search("IO STATUS");
  //   let segment = result.data.substr(
  //     headerSearch,
  //     result.data.length - headerSearch
  //   );
  //   let splitResult = segment.split("\n");
  //   // Let we further split based on a lot of spaces
  //   let furtherSplitResult = [];
  //   for (let split of splitResult) {
  //     let eachFurtherSplit = split.split("      ");
  //     furtherSplitResult = [...furtherSplitResult, ...eachFurtherSplit];
  //   }

  //   // Filtered out empty entries and split by space
  //   let arrayOfObj = [];
  //   for (
  //     let i = 0;
  //     i <
  //     furtherSplitResult.filter((elem) => {
  //       return removeFrontSpace(elem) !== "";
  //     }).length;
  //     ++i
  //   ) {
  //     let currentItem = furtherSplitResult.filter((elem) => {
  //       return removeFrontSpace(elem) !== "";
  //     })[i];
  //     let newCurrentItem = "";
  //     for (let currentchar of currentItem) {
  //       if (currentchar === "[" || currentchar === "]") {
  //         newCurrentItem = newCurrentItem + " " + currentchar + " ";
  //       } else {
  //         newCurrentItem = newCurrentItem + currentchar;
  //       }
  //     }

  //     let splitCurrent = newCurrentItem.split(" ").filter((elem) => {
  //       return elem !== "";
  //     });
  //     arrayOfObj.push(splitCurrent);
  //   }

  //   // Format the value to db
  //   let insert = [];
  //   for (let j = 0; j < arrayOfObj.length; ++j) {
  //     let type = null;
  //     let number = null;
  //     let value = null;
  //     let comment = "";
  //     let eachObj = arrayOfObj[j];
  //     if (eachObj.includes("[")) {
  //       let index = eachObj.findIndex((elem) => {
  //         return elem === "[";
  //       });
  //       let finishIndex = eachObj.findIndex((elem) => {
  //         return elem === "]";
  //       });
  //       if (index >= 1 && finishIndex < eachObj.length - 1) {
  //         type = eachObj[index - 1];
  //         number = eachObj[index + 1];
  //         value = eachObj[finishIndex + 1];
  //         for (let k = finishIndex + 2; k < eachObj.length; ++k) {
  //           comment = comment + eachObj[k] + " ";
  //         }

  //         insert.push({
  //           register_type: type,
  //           register_name: comment,
  //           register_value: value,
  //           register_number: number,
  //         });
  //       }
  //     }
  //   }

  //   let time = insert.filter((elem) => {
  //     return elem.register_type === "GOUT" && elem.register_number === "1";
  //   })[0].register_value;

  //   return [
  //     {
  //       machine: "Bar Robot",
  //       program_name: "TEST_PROG",
  //       program_time: time,
  //     },
  //   ];
  // }

  async function reloadData() {
    let id = setInterval(async () => {
      // let intervalID = checkStatus();
      // let newStatus = await getRobotStatus(status);
      // //console.log("First", newStatus);
      await getData();
      // setStatus(newStatus);
      // setLoading(false);
      // setMount(true);
    }, 30000);
    setRefreshIntervalID(id);
  }

  async function loadData() {
    setLoading(true);
    await getData();
    setLoading(false);
    let id = setInterval(async () => {
      // let intervalID = checkStatus();
      // let newStatus = await getRobotStatus(status);
      // //console.log("First", newStatus);
      await getData();
      // setStatus(newStatus);
      // setLoading(false);
      // setMount(true);
    }, 30000);
    setRefreshIntervalID(id);
  }

  function checkStatus() {
    //console.log("Interval Status", status);
  }

  async function handleClick(id) {
    console.log("Clicked on Machine", id);
    setButtonLoading(true);

    let now = new Date();
    now.setTime(now.getTime() - 24 * 60 * 60 * 1000);
    setaxisStart(startInterval);
    setaxisEnd(endInterval);
    setButtonLoading(false);
    SetIndicator(false)

    if (id === "temp1" || id === "temp2" || id === "temp3" || id === "temp4") {
      const configNew = await axios.get(
        `${process.env.REACT_APP_URL}/ovenTemperature`,
        {
          params: {
            port: id[4] - 1,
            start: startInterval,
            end: endInterval,
          },
        }
      );
      let newStatus = { ...status };
      newStatus["id"] = {
        ...newStatus["id"],
        open: true,
      };
      setStatus(newStatus);
      setType(id);
      setData(configNew.data);

      //  setStart(datetrans(now).findPreviousIntegerHours().toISOString());
      //  setEnd(datetrans(new Date()).findNextIntegerHours().toISOString());

      return;
    } else {
      const configNew = await axios.get(
        `${process.env.REACT_APP_URL}/winderRoomTemperature`,
        {
          params: {
            start: startInterval,
            end: endInterval,
          },
        }
      );
      let newStatus = { ...status };
      newStatus["humid"] = {
        ...newStatus["humid"],
        open: true,
      };
      setStatus(newStatus);
      setType(id);
      setData(configNew.data);

      return;
    }
    // const configNew = await axios.get(`${process.env.REACT_APP_URL}/day`, {
    //   params: {
    //     machine: id,
    //   },
    // });

    // //console.log("ConfigNew", configNew.data);
    // setData(configNew.data);
    // let newStatus = { ...status };
    // newStatus[id] = {
    //   ...newStatus[id],
    //   open: true,
    // };
    // setStatus(newStatus);
    // SetIndicator(false);
    // setType("col");
  }

  useEffect(() => {
    loadData();
    // getData();
  }, []);

  return (
    <div>
      <div className="subheader">
        <h4 className="statusHeadline">
          Last Refresh Time:{" "}
          {refreshTime.toLocaleDateString("default", {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
          })}
        </h4>
      </div>
      <section className="grid">
        <div className="grid-left">
          {/* <div className="map-header">
            <ChartLegend />
          </div> */}
          <div className="mapContainer">
            <Map
              status={status}
              onClick={async (id) => {
                await handleClick(id);
              }}
              loading={buttonloading}
            />
          </div>
        </div>
        {Object.keys(status).map((elem) => {
          return (
            <MachineStatus
              open={status[elem].open}
              name={type}
              data={data}
              start={startInterval}
              displaystart={axisStartInterval}
              displayEnd={axisendInterval}
              changeStart={(dateObj) => {
                setStart(dateObj);
              }}
              changeEnd={(dateObj) => {
                setEnd(dateObj);
              }}
              end={endInterval}
              loading={buttonloading}
              close={() => {
                //console.log("Closing");
                let newStatus = { ...status };
                newStatus[elem] = {
                  ...status[elem],
                  open: false,
                };
                setStatus(newStatus);
                SetIndicator(true);
              }}
              reloadData={async () => {
                if (
                  elem === "temp1" ||
                  elem === "temp2" ||
                  elem === "temp3" ||
                  elem === "temp4"
                ) {
                  const configNew = await axios.get(
                    `${process.env.REACT_APP_URL}/ovenTemperature`,
                    {
                      params: {
                        port: elem[4],
                        start: startInterval,
                        end: endInterval,
                      },
                    }
                  );
                  setData(configNew.data);
                  // console.log("Data", data)

                  //  setStart(datetrans(now).findPreviousIntegerHours().toISOString());
                  //  setEnd(datetrans(new Date()).findNextIntegerHours().toISOString());

                  return;
                } else {
                  const configNew = await axios.get(
                    `${process.env.REACT_APP_URL}/winderRoomTemperature`,
                    {
                      params: {
                        start: startInterval,
                        end: endInterval,
                      },
                    }
                  );
                  setData(configNew.data);
                  return;
                }
              }}
            />
          );
        })}
        {/* <div className="status-scroll">
          <Status
            onClick={async (id) => {
              await handleClick(id);
            }}
            status={status}
            machines={machines}
            loading={loading}
          />
        </div>
        <div>
          {machines.map((elem) => {
            return (
              <MachineStatus
                // dataSource={status[elem].dataSource}
                // columns={status[elem].columns}
                open={status[elem.machine_code].open}
                name={elem}
                data={data}
                start={startInterval}
                displaystart={axisStartInterval}
                displayEnd={axisendInterval}
                changeStart={(dateObj) => {
                  setStart(dateObj);
                }}
                changeEnd={(dateObj) => {
                  setEnd(dateObj);
                }}
                end={endInterval}
                loading={buttonloading}
                reloadData={async () => {
                  setButtonLoading(true);
                  let configNew;
                  console.log("Reload Data", type);
                  switch (type) {
                    case "winderhumidity":
                      configNew = await axios.get(
                        `${process.env.REACT_APP_URL}/winderRoomTemperature`,
                        {
                          params: {
                            start: startInterval,
                            end: endInterval,
                          },
                        }
                      );
                      break;
                    case "windertemp":
                      configNew = await axios.get(
                        `${process.env.REACT_APP_URL}/winderRoomTemperature`,
                        {
                          params: {
                            start: startInterval,
                            end: endInterval,
                          },
                        }
                      );
                      break;
                    case "temp":
                      configNew = await axios.get(
                        `${process.env.REACT_APP_URL}/ovenTemperature`,
                        {
                          params: {
                            start: startInterval,
                            end: endInterval,
                          },
                        }
                      );
                      break;
                    case "irtemp":
                      configNew = await axios.get(
                        `${process.env.REACT_APP_URL}/IRTemperature`,
                        {
                          params: {
                            start: startInterval,
                            end: endInterval,
                          },
                        }
                      );
                      break; 
                    default:
                      break;
                  }
                  console.log(
                    "diff",
                    (new Date(endInterval) - new Date(startInterval)) /
                      (60 * 60 * 1000)
                  );

                  setData(configNew.data);
                  setaxisStart(startInterval);
                  setaxisEnd(endInterval);
                  setButtonLoading(false);
                }}
                type={type}
                close={() => {
                  //console.log("Closing");
                  let newStatus = { ...status };
                  newStatus[elem.machine_code] = {
                    ...status[elem.machine_code],
                    open: false,
                  };
                  setStatus(newStatus);
                  SetIndicator(true);
                }}
              />
            );
          })}
        </div> */}
      </section>
    </div>
  );
}
