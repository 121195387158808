import React, { useEffect } from "react";
import { useState } from "react";
import MachineStatus from "./Machine.component";
import axios from "axios";
import "./map.styles.css";
import { Player } from "@lottiefiles/react-lottie-player";
import ChartLegend from "../Chart/ChartLegend.component";
import Placeholder from "../assets/placeholder.jpg"
import { Tooltip, Modal } from "@alpaca/framework"

const computeAvg = (v1, v2, v3, v4, v5, v6) => {
  return (
    Math.floor(
      (Number(v1) +
        Number(v2) +
        Number(v3) +
        Number(v4) +
        Number(v5) +
        Number(v6)) /
      6
      + 0.5
    ));
};


export default function Map(props) {
  const [tables, setTables] = useState([]);

  function genereateCSS(id) {

    let statusObj = props.status[id];

    //console.log("Status Obj", statusObj);
    return {
      style: {
        fill: statusObj?.status === 'on' ? "#90C63D" : statusObj?.status === 'idle' ? '#FFAD1F' : statusObj?.status === 'off' ? "#EC1E24" : "#7e757e",
        stroke: statusObj?.status === 'on' ? "#90C63D" : statusObj?.status === 'idle' ? '#FFAD1F' : statusObj?.status === 'off' ? "#EC1E24" : "#7e757e"
      },
      onClick: () => {
        props.onClick(id);
      },
    };
  }

  return props.loading ? (
    <div>
      <Player
        autoplay
        loop
        src="https://lottie.host/44dad6c8-4e8a-429a-bf83-6a69941c0d3d/RSsIk8pTbT.json"
        style={{ width: "200px" }}
        className="spinner"
      ></Player>
    </div>
  ) : (
    <div className="img-container">
      <div className="img">
        <h1>Large Oven</h1>
        <div className="indc-1">
          <div className="indc" onClick={() => {
            props.onClick("temp1")
          }}>{props.status["temp1"].temperature}°C</div>
        </div>
      </div>

      <div className="img">
      <h1>Extrusion Press</h1>

        <div className="indc-1">
          <div className="indc" onClick={() => {
            props.onClick("temp2")
          }}>{props.status["temp2"].temperature}°C</div>
        </div>
      </div>

      <div className="img assembly-img">
        <h1>Material Storage</h1>
        <div className="indc-1" >
          <div className="indc" onClick={() => {
              props.onClick("humidtemp")
            }}>{props.status["humid"].temperature}°C</div>
          </div>
          <div className="indc-1" onClick={() => {
              props.onClick("humid")
            }}>
          <div className="indc">{props.status["humid"].humidity}Rh</div>
        </div>
      </div>

      <div className="img">
        <h1>Vulcanizing Press</h1>
        <div className="indc-1">
          <div className="indc" onClick={() => {
            props.onClick("temp3")
          }}>{props.status["temp3"].temperature}°C</div>
        </div>
      </div>

      <div className="img">
        <h1>Small Oven</h1>
        <div className="indc-1">
          <div className="indc" onClick={() => {
            props.onClick("temp4")
          }}>{props.status["temp4"].temperature}°C</div>
        </div>
      </div>
    </div>
  );
}
