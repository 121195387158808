function datetrans (dateObj) { 
    // It takes a date obj
    let hours = dateObj.getHours(); 
    let minutes = dateObj.getMinutes(); 
    let date = dateObj.getDate(); 
    let seconds = dateObj.getSeconds(); 
    let month = dateObj.getMonth() + 1; 
    let year = dateObj.getFullYear(); 


    function shiftForward() { 
        let diff = new Date().getTimezoneOffset() * 60 * 1000; 
        let dateCopyObj = new Date(dateObj.toISOString())
        dateCopyObj.setTime(dateCopyObj.getTime() + diff)
        return dateCopyObj
     }


    function zeroPadding(n) { 
        // it takes a integer n and format it based on the number of digits
        if (0 <= n && n <= 9) { 
            return `0${n}`
        }
        if (n < 0 || n >= 60) { 
            return 'Invalid Time'
        }
        else { 
            return `${n}`
        }
    }

    function formatTime() { 
        return `${hours}:${zeroPadding(minutes)}:${zeroPadding(seconds)}`
    }

    function monthName(n) {

        if ( n < 1) { 
            return 'Invalid Date'
        }
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        
        return monthNames[n - 1]
    }

    function formatDate() { 
        return `${monthName(month)} ${date}, ${year}`
    }


    function formatCSVDate() { 
        // Since, is used as a separator 
        return `${year}-${month}-${date}`
    }

    function formatCsv() { 
        return formatCSVDate() + "," + formatTime(); 
    }

    function formatX() { 
        return formatDate() + " " + formatTime();   
    }

    function findPreviousIntegerHours() { 
        let dateObjCopy = new Date(dateObj.toISOString());
        let milliseconds = dateObjCopy.getTime();
        const oneHr = 60 * 60 * 1000;
        let remainingmilliseconds = milliseconds % oneHr;
        dateObjCopy.setTime(milliseconds - remainingmilliseconds);
        return dateObjCopy;
    }

    function findNextIntegerHours() { 
        let dateObjCopy = new Date(dateObj.toISOString());
        let milliseconds = dateObjCopy.getTime();
        const oneHr = 60 * 60 * 1000;
        let remainingmilliseconds = milliseconds % oneHr;
        dateObjCopy.setTime(milliseconds - remainingmilliseconds + oneHr);
        return dateObjCopy;
    }



    function formatHours() { 
        return hours + " : " + zeroPadding(minutes);  
    }

    return { 
        formatCsv: formatCsv, 
        formatX: formatX, 
        formatHours: formatHours, 
        shiftForward: shiftForward, 
        findPreviousIntegerHours: findPreviousIntegerHours, 
        findNextIntegerHours: findNextIntegerHours
    }
}

module.exports = { 
    datetrans
}